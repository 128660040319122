<template>
    <v-card width="100%" tile height="100%" class="pa-0 ma-0">
        <v-container fluid class="pa-0" fill-height>
            <v-row align="stretch" class="fill-height">
                <v-col cols="12" md="5" class="text-center pa-0" order="2" order-md="2" v-if="!$vuetify.breakpoint.mobile">
                    <v-card color="primary" tile height="100%" class="pa-10 mx-auto" flat dark>
                        <v-row class="fill-height ma-0" justify="center">
                            <v-col>
                                <h1>Gestão de Privacidade e Segurança de Dados</h1>
                                <h3>
                                    Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD)
                                </h3>
                                <img src="@/assets/lgpd_digisystem_ibm.png" style="max-width:100%;" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="7" order="2" order-md="2">
                    <v-row class="fill-height" justify="center">
                        <v-col>
                            <div class="px-10">
                                <div class="text-center pa-5">
                                    <img src="@/assets/logo.png" width="400px" style="max-width:80%;" />
                                </div>
                                <template>
                                    <h1 class="mb-4">Termos de Uso</h1>
                                    <p class="mb-4">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio cum
                                        doloremque voluptate at nam quo, et eius libero amet sequi. Cum a
                                        mollitia illo dolores rem eveniet vero architecto accusantium.</p>
                                    <h3>1. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <h3>2. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <h3>3. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <h3>4. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <h3>5. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <h3>6. Lorem, ipsum dolor sit amet</h3>
                                    <p class="mb-4">In ipsum neque, placerat at vestibulum quis, accumsan in est. Mauris
                                        bibendum nec lorem non finibus. Pellentesque</p>
                                    <p>Reservamos o direito de modificar este Termo de Uso a qualquer momento, com
                                        notificação através do Sistema.</p>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
  
<script>


export default {
    // name: 'Register',
}
</script>
  